@import (reference) "./colors.less";

@textFieldPadding: 10px;

input, textarea, button {
	font-family: "Sora", sans-serif;
	margin: 0;
	padding: 0;
}

input[type="search"] {
	-webkit-appearance: none;
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

button {
	background: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
	text-align: left;

	&:focus {
		outline: none;
	}
}

.link {
	color: #767676;

	&:hover {
		color: #4A4A4A;
		text-decoration: underline;
	}
}

.brand-link {
	color: @brandPrimary;
	font-size: 14px;

	&:hover {
		color: @brandPrimaryHover;
		text-decoration: underline;
	}
}

.brand-secondary-link {
	color: @brandSecondary;

	&:hover {
		color: @brandSecondaryHover;
		text-decoration: underline;
	}
}

.minor-action-link {
	font-size: 12px;
	color: #8E8E8E;
	text-decoration: none;

	&:hover {
		color: #4A4A4A;
		text-decoration: underline;
	}

	&:visited {
		color: inherit;
	}

	&:link {
		color: inherit;
	}
}

.cta-button-base() {
	align-items: center;
	background: @brandPrimary;
	border-radius: 3px;
	border: none;
	box-shadow: 0 1px 2px 0 rgba(140,140,140,0.50);
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	justify-content: center;
	text-align: center;

	&:focus {
		outline: none;
		background: @brandPrimaryHover;
	}

	&:hover {
		background: @brandPrimaryHover;
	}

	&:active {
		background: @brandPrimaryActive;
	}
}

.cta-button {
	.cta-button-base();
	padding: 10px 20px;
	min-width: 90px;
}

.cta-button-destructive {
	.cta-button();
	background: @destructive;

	&:hover {
		background: @destructiveHover;
	}

	&:active {
		background: @destructiveActive;
	}
}

.cta-button-secondary {
	.cta-button();
	background: @brandSecondary;

	&:hover {
		background: @brandSecondaryHover;
	}

	&:active {
		background: @brandSecondaryActive;
	}
}

.cta-button-reverse {
	.cta-button-base();
	background: white;
	border: 1px solid @brandPrimary;
	box-shadow: 0 1px 2px 0 #f9f9f9;
	color: #8E8E8E;
	min-width: 90px;
	padding: 10px 20px;

	&:hover {
		background-color: #F3F8FB;
	}

	&:active {
		background: #E8F3F9;
	}
}

.cta-button-reverse-destructive {
	.cta-button-reverse();
	background: transparent;
	border: 1px solid @destructive;
	color: @destructive;
	
	&:hover {
		background: transparent;
		border-color: @destructiveHover;
		color: @destructiveHover;
	}
}

.cta-button-reverse-small {
	.cta-button-reverse();
	padding: 4px 14px;
	min-width: 60px;
}

.cta-button-small {
	.cta-button-base();
	padding: 4px 14px;
	min-width: 60px;
}

.remove-input-spin-button() {
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
}

.text-field() {
	-webkit-appearance: none;
	background: #F9F9F9;
	border-radius: 3px;
	border: 1px solid @inputBorderColor;
	box-sizing: border-box;
	color: @titles;
	font-size: 14px;
	padding: @textFieldPadding;
}

.modal-content-height-constraints() {
	height: 380px;

	@media only screen and (min-height: 768px) {
		height: auto;
		min-height: 380px;
		max-height: 500px;
	}

	@media only screen and (min-height: 1024px) {
		height: auto;
		max-height: 660px;
		min-height: 380px;
	}
}