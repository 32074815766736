@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/inputs.less";

.text-input {
	.text-field();
	align-items: center;
	display: flex;
	box-sizing: border-box;

	input {
		background: transparent;
		border: none;
		color: @titles;
		flex-grow: 1;
		font-size: 14px;
		padding: 0;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: #A7ABAD;
		}
	}
}